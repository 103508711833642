import React, { Fragment, Suspense, lazy } from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import '../styles/main.css'
import Header from './Header'
import Footer from './Footer'

const Home = lazy(() => import('../pages/Home'))

function AppRouter () {
  return (
    <>
    <div className='min-h-screen flex flex-col bg-gradient-to-br dark:from-slate-800 dark:to-slate-900 dark:text-slate-300 from-slate-100 to-slate-200 text-slate-700'>
      <Header />
        <div className='flex flex-col items-center flex-1'>
          <Router>
            <Routes>
              <Route path="/" element={
                <Suspense fallback={<>Loading...</>}>
                  <Home />
                </Suspense>
              } />
            </Routes>
          </Router>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default AppRouter
